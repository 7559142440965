@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden !important;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Ubuntu;
  color: #073756;
}
p {
  font-family: Quattrocento Sans;
  color: #333333;
}
ul li,
ol li {
  font-family: Quattrocento Sans;
  color: #333333;
}
table thead,
tr {
  color: #333333;
}
#breadcrumb {
  color: #777777;
}
.darren-bg {
  background-image: url("/public/images/darren-bg.svg");
  background-repeat: no-repeat;
}
.milestone-bg-top {
  background-image: url("/public/images/milestone-bg-top.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.milestone-bg-bottom {
  background-image: url("/public/images/milestone-bg-bottom.svg");
  background-repeat: no-repeat;
}
.footer {
  background-image: url("/public/images/map-world.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.adults {
  background-image: url("/public/images/adults-class.jpg");
  background-repeat: no-repeat;
}
.kids {
  background-image: url("/public/images/kids-class.jpg");
  background-repeat: no-repeat;
}
.courses-program-left {
  background-image: url("/public/images/course-program-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.courses-program-right {
  background-image: url("/public/images/course-program-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.chtext:hover {
  font-size: 0;
  text-align: center;
}

.chtext:hover:before {
  font-size: 30px;
  content: attr(data-hover);
}
.blog-content p {
  font-size: 18px;
  margin: 5px 0;
  font-family: Quattrocento Sans;
  color: #333333;
}
.blog-content h2 {
  font-size: 24px;
  margin: 10px 0;
  font-weight: bold;
  font-family: Ubuntu;
  color: #333333;
}
.blog-content a {
  color: #ee4723;
}
.blog-content ul {
  margin-left: 20px;
}
.blog-content ul li {
  list-style: disc;
  font-size: 18px;
  margin: 10px 0;
  color: #333333;
}
.blog-content hr {
  margin: 20px 0;
  color: #333333;
}

th,
td {
  padding: 10px;
}
tr {
  border-bottom: solid 1px #e5e7eb;
}
.react-tabs__tab--selected {
  background: #ee4723 !important;
  border-color: #ee4723 !important;
  color: #ffffff !important;
}
.react-tabs__tab {
  border: 1px dashed #ee4723 !important;
  border-radius: 5px 5px 0 0;
  color: #ee4723;
  font-weight: bold;
}
.collapse:not(.collapse-close)
  :where(input[type="checkbox"]:checked ~ .collapse-content) {
  padding-bottom: 0;
}
.aligncenter {
  margin: 0px auto;
}
@media (min-width: 320px) and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
  }
}
